import { useMutation } from '@apollo/client';
import { CREATE_OPERATIVE_REQUEST } from '../graphql';
import useSnackBars from './useSnackBars';

const useRaiseOperativeRequest = (requestType, documentType, documentIds, comment) => {
  const { addAlert } = useSnackBars();
  const [raiseOperativeRequest, { loading }] = useMutation(CREATE_OPERATIVE_REQUEST, {
    variables: {
      requestType,
      documentType,
      documentIds,
      comment,
    },
    onCompleted: () => {
      addAlert({ id: 'success-operative-request', color: 'success', message: '¡Solicitud enviada exitosamente!' });
    },
    onError: (err) => {
      addAlert({ id: 'success-operative-request', color: 'error', message: `Error: ${err.message}` });
    },
  });
  return [raiseOperativeRequest, loading];
};

export default useRaiseOperativeRequest;
