import { useMemo } from 'react';

const useInvoiceRequirementsStates = (invoice) => {
  const { assignmentSet, company, isFirstOperation } = invoice;
  const {
    documents, companyevaluationrestrictionsSet: restrictions, masterEntity, legalRepresentative,
  } = company;
  const nonCompliant = legalRepresentative?.find((LR) => {
    const compliance = LR.relatedPerson?.compliance;
    return compliance && compliance.requirementsClean === false;
  });
  const compliance = masterEntity.compliance || {};
  const contract = documents?.find(({ documentType }) => documentType === 'contract');
  const riskRestrictions = restrictions.filter(({ status }) => ['CREATED', 'PENDING'].includes(status));
  const { regcheqId } = compliance || {};
  const BFStatus = nonCompliant ? 'REJECTED' : compliance.status;
  const assignment = useMemo(() => {
    if (!assignmentSet) return null;
    if (!assignmentSet[0]) return null;
    if (!assignmentSet[0].certificate) return null;
    return assignmentSet[0];
  }, [assignmentSet]);

  return {
    isFirstOperation,
    company,
    compliance,
    contract,
    riskRestrictions,
    BFStatus,
    regcheqId,
    assignment,
    nonCompliant,
  };
};

export default useInvoiceRequirementsStates;
