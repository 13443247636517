import { useMemo } from 'react';

const useCompanyRequirementsStates = (masterEntity) => {
  const { company } = masterEntity;
  const {
    documents, companyevaluationrestrictionsSet: restrictions, legalRepresentative,
    hasMultipleOperations,
  } = company;
  const nonCompliant = legalRepresentative?.find((LR) => {
    const compliance = LR.relatedPerson?.compliance;
    return compliance && compliance.requirementsClean === false;
  });
  const compliance = masterEntity.compliance || {};
  const contract = documents?.find(({ documentType }) => documentType === 'contract');
  const riskRestrictions = restrictions.filter(({ status }) => ['CREATED', 'PENDING'].includes(status));
  const { regcheqId } = compliance || {};
  const BFStatus = nonCompliant ? 'REJECTED' : compliance.status;

  const memoizedResult = useMemo(() => ({
    company,
    compliance,
    contract,
    hasMultipleOperations,
    riskRestrictions,
    BFStatus,
    regcheqId,
    nonCompliant,
  }), [company, compliance, contract, hasMultipleOperations, riskRestrictions,
    BFStatus, regcheqId, nonCompliant]);

  return memoizedResult;
};

export default useCompanyRequirementsStates;
